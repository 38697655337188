
.scrollbar-content{
    height: calc(100vh - 200px);
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.page-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin: 20px 0;
    line-height: 1;
}
.goods-overview {
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    .title {
        font-size: 16px;
        line-height: 1;
    }
    .overview-content {
        margin-top: 20px;
        border: 1px solid #E5E5E5;
        display: flex;
        .oo-item {
            width: 50%;
            display: flex;
            align-items: center;
            .oo-title {
                width: 112px;
                height: 112px;
                background: #F8F8F8;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 42px;
                    margin-bottom: 2px;
                }
            }
            .oo-right {
                width: 1%;
                flex: 1;
                display: flex;
                .item {
                    width: 33.333333%;
                    padding-left: 20px;
                    p {
                        line-height: 1;
                        white-space: nowrap;
                    }
                    .text {
                        font-size: 16px;
                    }
                    .num {
                        font-size: 18px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
.trend-wrapper {
    background: #fff;
    padding: 20px;
    .trend-category {
        display: flex;
        border: 1px solid #E5E5E5;
        border-top: 0;
        .cate-left {
            width: 120px;
            background: #F8F8F8;
            border-top: 1px solid #E5E5E5;
            p {
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                border-bottom: 1px solid #E5E5E5;
                margin: 0;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        .cate-right {
            width: 1%;
            flex: 1;
            .el-checkbox-group {
                display: flex;
                flex-wrap: wrap;
                .el-checkbox {
                    margin-right: 0;
                    line-height: 50px;
                    padding-left: 48px;
                    width: 15%;
                    box-sizing: border-box;
                    border-top: 1px solid #E5E5E5;
                    &:nth-of-type(2), &:nth-of-type(4) {
                        width: 85%;
                    }
                    &:last-of-type {
                        width: 40%;
                    }
                    /*&:nth-of-type(5) {*/
                    /*    width: 70%;*/
                    /*}*/
                    /*&:nth-of-type(1), &:nth-of-type(2) {*/
                    /*    border-bottom: 1px solid #E5E5E5;*/
                    /*}*/
                }
            }
        }
    }
    .choose-number {
        margin-top: 30px;
        font-size: 18px;
        .number {
            margin-left: 20px;
            span {
                color: #0084FF;
            }
        }
    }
    .trend-content {
        margin-top: 30px;
        height: 300px;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #0084FF;
        border-color: #0084FF;
    }
    ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #0084FF;
    }
    ::v-deep .el-checkbox__inner:hover {
        border-color: #0084FF;
    }
}
